<template>
  <div>
      <div>
        <div>
          <div>
            <p class="mb-0">{{organisme.sigle}} - {{organisme.typeOrgSignification}}</p>
            <p class="mb-0" v-if="organisme.nomOrg !== null"><strong>{{organisme.nomOrg}}</strong></p>
            <p class="mb-0" v-if="organisme.service !== null">{{organisme.service}}</p>
            <p class="mb-0" v-if="organisme.adresse !== null">{{organisme.adresse}}</p>
            <p class="mb-0" v-if="organisme.villeOrg !== null">{{organisme.villeOrg}}</p><br>

            <p class="mb-0" v-if="organisme.contact !== null">{{organisme.contact}}</p>
            <p class="mb-0" v-if="organisme.email !== null">{{organisme.email}}</p>
            <p class="mb-0" v-if="organisme.telephone !== null">{{organisme.telephone}}</p>
            <p class="mb-0" v-if="organisme.web !== null">
              <a :href="organisme.web" target="_blank"
               :style="{ color: webColor}" @mouseover="webColor = '#ffffff'" 
               @mouseout="webColor = '#676834'">
                <strong>Site Web</strong>
              </a>
            </p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>


import {getAll} from "@/composition/afficherOrganismes";
import { ref } from 'vue';

export default {
  props:{
    organisme: Object
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
     setup(){
       const {
         organismes
       } = getAll()
       const webColor = ref('#676834');
       return {
         organismes,
         webColor
       }
     }
}

</script>
<style>


</style>