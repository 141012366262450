import {ref} from "vue";
import http from '@/http';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getAll (){
    const organismes = ref([])
    const typeOrg = ref("")
    const search = ref("")
    const resultOrganisme = ref(true)

    const getOrganismesByCriteria = async function(criteria){
        resultOrganisme.value= true
        http.getOrganismesByCriteria(criteria).then((data)=>{
            organismes.value=data
            if (organismes.value.length ===0){
                resultOrganisme.value = false
            }
        })
    }

    const searchOrganismes = async function(){
        resultOrganisme.value= true
            http.searchOrganismes(search.value,typeOrg.value).then((data)=>{
                organismes.value = data
                if (organismes.value.length ===0){
                    resultOrganisme.value = false
                }
            })

    }
    return {
        typeOrg,
        search,
        organismes,
        resultOrganisme,
        searchOrganismes,
        getOrganismesByCriteria
    }
}