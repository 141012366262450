<template>
  <div class="organismes">
    <h2 class="text-2xl mb-4 title-search">Rechercher un organisme</h2>
    <div class="grid grid-cols-6 mb-4">
      <div class="search col-start-2 col-span-4 w-full max-w-7xl">
        <form @submit.prevent="goToPage(1);searchOrganismes(search,typeOrg)">
            <div class="searchForm">
              <div>
                <label class="block text-gray-700 text-sm font-bold mb-2 mt-4">
                  Mot(s) clef
                </label>
                <input v-model="search" @keydown.enter="searchOrganismes(search, typeOrg)" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text">
                <p class="mt-2">Ex: 45, hérault, Paris, Chambre régionale, institut ...</p>
              </div>

              <div class="pb-4">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
                  Type d'organisme:
                </label>
                <div class="relative">
                  <select v-model="typeOrg" @keydown.enter="searchOrganismes(search, typeOrg)" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option value="">Tous les types</option>
                    <option v-for="option in listTypesOrg" :key="option.value" :value="option.value">{{ option.label }}</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
                </div>
              </div>
                <button class="btn-organisme" type="submit">Rechercher</button>
                <button class="btn-organisme" type="button" @click="resetForm">Réinitialiser</button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="organismes.length!==0">
      <div>
        <h2 class="text-2xl mb-3">{{organismes.length}} résultats pour votre recherche</h2>
        <p class="mb-3">Trier par:
          <button class="btn-trie" type="button" @click="getOrganismesByCriteria('nom')">Nom</button>
          <button class="btn-trie" type="button" @click="getOrganismesByCriteria('sigle')">Sigle</button>
          <button class="btn-trie" type="button" @click="getOrganismesByCriteria('ville')">Ville</button>
        </p>
      </div>
      <div class="pagination">
        <button class="btn-pagination" type="button" @click="goToPage(1)">&laquo;</button>
        <button class="btn-pagination" type="button" @click="changePage(-1)" :disabled="currentPage === 1">Précédent</button>
        <button v-for="pageNumber in visiblePages" :key="pageNumber" @click="goToPage(pageNumber)" :class="{ 'btn-pagination': true, 'active': pageNumber === currentPage }">{{ pageNumber }}</button>
        <button class="btn-pagination" type="button" @click="changePage(1)" :disabled="currentPage === totalPages">Suivant</button>
        <button class="btn-pagination" type="button" @click="goToPage(totalPages)">&raquo;</button>
      </div>


      <div class="grid grid-cols-12">
        <div class="loadOrganismes col-start-2 col-span-10" v-bind:key ="organisme.idOrganisme" v-for="organisme in paginatedData">
          <Organisme :organisme="organisme"></Organisme>
        </div>
      </div>

      <div class="pagination">
        <button class="btn-pagination" type="button" @click="goToPage(1)">&laquo;</button>
        <button class="btn-pagination" type="button" @click="changePage(-1)" :disabled="currentPage === 1">Précédent</button>
        <button v-for="pageNumber in visiblePages" :key="pageNumber" @click="goToPage(pageNumber)" :class="{ 'btn-pagination': true, 'active': pageNumber === currentPage }">{{ pageNumber }}</button>
        <button class="btn-pagination" type="button" @click="changePage(1)" :disabled="currentPage === totalPages">Suivant</button>
        <button class="btn-pagination" type="button" @click="goToPage(totalPages)">&raquo;</button>
      </div>

    </div>
    

    <div v-if="!resultOrganisme">
      <h2 class="text-2xl mb-3">Aucun résultat trouvé pour votre recherche</h2>
    </div>


  </div>
</template>

<script>
import Organisme from "@/components/Organisme.vue";
import {getAll} from "@/composition/afficherOrganismes";
import {usePagination} from "@/composition/pagination";
import {listSelect} from "@/composition/listSelect";

export default ({
  name: 'Organismes',
  components:{
    Organisme
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(){

    const resetForm = async function(){
      search.value ="";
      typeOrg.value ="";
      organismes.value =[];
    }

    const{
      listTypesOrg
    } = listSelect()

    const {
      search,
      typeOrg,
      organismes,
      resultOrganisme,
      searchOrganismes,
      getOrganismesByCriteria
    } = getAll()

    const{
      pageSize,
      currentPage,
      totalPages,
      paginatedData,
      changePage,
      goToPage,
      visiblePages,
    } = usePagination(organismes)

    return {
      pageSize,
      currentPage,
      totalPages,
      paginatedData,
      changePage,
      goToPage,
      visiblePages,
      search,
      typeOrg,
      listTypesOrg,
      organismes,
      resultOrganisme,
      searchOrganismes,
      getOrganismesByCriteria,
      resetForm
    }
  }

});
</script>

<style>

.organismes{
  @apply mt-1;
  min-height: 640px;
}
.search{
  @apply text-left border-solid border-2;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.searchForm{
  @apply ml-6 mr-6 mb-4
}
.loadOrganismes{
  @apply text-left ml-24 mr-24 mb-5 bg-blue-400 text-black p-6 border-2;
  background-color: 	#d6dbdd;
}
.btn-organisme{
  @apply font-bold py-2 px-4 rounded-full bg-gray-400 text-white;
  margin: 1em;
}
.btn-organisme:hover {
  @apply bg-gray-500;
}
.btn-trie{
  @apply font-bold py-2 px-4 rounded-full bg-blue-900 text-white;
  margin: 1em;
}
.btn-trie:hover {
  @apply bg-blue-400;
}
</style>